import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../components/Constant';
import CurrencyFormat from 'react-currency-format';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from '../user/payment/PaymentForm'
import {
  Row,
  Col,
  Button,
  Container
} from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  AddNew,
  AddNewWineRounded,
  TotalSellingPrice,
  TotalCost,
  AddExisting,
  Remove,
  Transfer
} from '../../components/assets'


const UserHome = () => {

  const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);

  const [InventoryData, setInventoryData] = useState({
    bottles: 0,
    total_cost: 0,
    total_selling_price: 0
  });
  const { bottles, total_cost, total_selling_price } = InventoryData;

  useEffect(() => {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "get_inventory_count", {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setInventoryData({ bottles: res.data.bottles, total_cost: res.data.total_cost, total_selling_price: res.data.total_selling_price })
        } else {
          NotificationManager.error(res.data.message, 'Error', 2000);
        }
      })
      .catch(function (error) { });
  }, [])



  return (
    <>
      <NotificationContainer />
      <Container>
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      </Container>

      <Row>
        <Col className="py-3">
          <NavLink to="/add-wine" className="text-decoration-none"> <Button block size="lg" color="skyblue"><img src={AddNew} alt="Add New Icon" className="img-fluid" /> Add New</Button></NavLink>
        </Col>
      </Row>

      <Row>
        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/wines" className="dashboard-box gradient-blue py-3 py-md-5 text-center">
            <img src={AddNewWineRounded} alt="Total Bottles" className="img-fluid mb-5" />

            <h3><CurrencyFormat value={bottles} displayType={'text'} thousandSeparator={true} /></h3>
            <h4>Total Bottles</h4>
          </NavLink>
        </Col>

        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/dashboard" className="dashboard-box gradient-violet py-3 py-md-5 text-center">
            <img src={TotalCost} alt="Total Cost" className="img-fluid mb-5" />

            <h3><CurrencyFormat value={total_cost} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
            <h4>Total Cost</h4>
          </NavLink>
        </Col>

        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/dashboard" className="dashboard-box gradient-pink py-3 py-md-5 text-center">
            <img src={TotalSellingPrice} alt="Total Selling Price" className="img-fluid mb-5" />

            <h3><CurrencyFormat value={total_selling_price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
            <h4>Selling Price</h4>
          </NavLink>
        </Col>

        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/add-inventory" className="dashboard-box py-3 py-md-4 text-center icon-small">
            <img src={AddExisting} alt="Add Existing" className="img-fluid mb-3" />

            <h4>Add Existing</h4>
          </NavLink>
        </Col>

        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/remove-inventory" className="dashboard-box bg-dark-500 py-3 py-md-4 text-center icon-small">
            <img src={Remove} alt="Remove" className="img-fluid mb-3" />

            <h4>Remove</h4>
          </NavLink>
        </Col>

        <Col md="6" lg="4" className="mb-4">
          <NavLink to="/transfer-inventory" className="dashboard-box py-3 py-md-4 text-center icon-small">
            <img src={Transfer} alt="Transfer" className="img-fluid mb-3" />

            <h4>Transfer</h4>
          </NavLink>
        </Col>
      </Row>
    </>);
}

export default UserHome;
