import React, { useState, useEffect } from 'react';
import PageTitle from '../../../components/pageTitle';
import axios from 'axios';
import { config } from '../../../components/Constant';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fab, fas, far)

const AddWine = (props) => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const { className } = props;
  const [winetype_modal, setwinetypeModal] = useState(false);
  const [location_modal, setLocationModal] = useState(false);
  const [selectLocation, setselectLocation] = useState('');
  const [selectWineType, setselectWineType] = useState('');
  const [locations, setLocations] = useState([]);
  const [wine_types, setWineTypes] = useState([]);

  const locationtoggle = () => setLocationModal(!location_modal);
  const wineTypetoggle = () => setwinetypeModal(!winetype_modal);


  useEffect(() => {
    getWineTypes();
    getLocatons();
  }, []);

  function getWineTypes() {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types", {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setWineTypes(res.data.wine_types)
        }
      })
      .catch(function (error) { });
  }

  function getLocatons() {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "locations", {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setLocations(res.data.locations)
        }
      })
      .catch(function (error) { });
  }

  const onSubmitWineType = (data, e) => {
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types", { wine_type: data }, {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          getWineTypes();
          NotificationManager.success(res.data.message, 'Done');
        } else {
          NotificationManager.error(res.data.message, 'Error');
        }
        wineTypetoggle();
      })
      .catch(function (error) { });
  }
  const onSubmitLocation = (data, e) => {
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "locations", { location: data }, {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          getLocatons();
          NotificationManager.success(res.data.message, 'Done');
        } else {
          NotificationManager.error(res.data.message, 'Error');
        }
        locationtoggle();
      })
      .catch(function (error) { });
  }

  const onSubmit = (data, e) => {
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "wines", { wine: data, location_id: selectLocation, wine_type_id: selectWineType }, {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          e.target.reset();
          NotificationManager.success(res.data.message, 'Done');
        } else {
          NotificationManager.error(res.data.message, 'Error');
        }
      })
      .catch(function (error) { });
  }

  const checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
        ? event.which
        : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }


  return (<>
    <NotificationContainer />
    <Row className="py-5">
      <Col>
        <PageTitle title="Add new wine to inventory" />
      </Col>
    </Row>

    <Form onSubmit={handleSubmit(onSubmit)} className="vint-vine-form">
      <Row>
        <Col md="6">
          <FormGroup>
            <Input type="text" name="upc" id="upc" placeholder="UPC" innerRef={register({
              required: true,
            })} />
            {_.get("upc.type", errors) === "required" && (
              <p className="input-error text-danger">* UPC required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="name" id="name" placeholder="Name" innerRef={register({
              required: true,
            })} />
            {_.get("name.type", errors) === "required" && (
              <p className="input-error text-danger">* Name required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <Row>
            <Col xs="7">
              <FormGroup>
                <select id="wineType" className="form-control" value={selectWineType} onChange={e => setselectWineType(e.target.value)}>
                  <option value="">Select Wine Type</option>
                  {wine_types.map((item, i) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </FormGroup>
            </Col>

            <Col xs="5">
              <Button block color="skyblue" onClick={wineTypetoggle}><FontAwesomeIcon icon="plus" /> Add Wine Type</Button>
            </Col>
          </Row>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="year" id="vintage" onKeyPress={checkNumeric} placeholder="Vintage - (Add Year)" innerRef={register({
              required: true
            })} />
            {_.get("year.type", errors) === "required" && (
              <p className="input-error text-danger">* Year required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="quantity" id="quantity" onKeyPress={checkNumeric} placeholder="Quantity" innerRef={register({
              required: true
            })} />
            {_.get("quantity.type", errors) === "required" && (
              <p className="input-error text-danger">* Quantity required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="cost" id="cost" placeholder="Cost" innerRef={register({
              required: true
            })} />
            {_.get("cost.type", errors) === "required" && (
              <p className="input-error text-danger">* Cost required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="price" id="sellingPrice" placeholder="Selling Price" innerRef={register({
              required: true
            })} />
            {_.get("price.type", errors) === "required" && (
              <p className="input-error text-danger">* Price required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <Row>
            <Col xs="7">
              <FormGroup>
                <select className="form-control" value={selectLocation} onChange={e => setselectLocation(e.target.value)}>
                  <option value="">Select Location</option>
                  {locations.map((item, i) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </FormGroup>
            </Col>

            <Col xs="5">
              <Button block color="skyblue" onClick={locationtoggle}><FontAwesomeIcon icon="plus" /> Add Location</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="text-right mt-3 mt-md-5">
          <Button type="submit" size="lg" color="link">Confirm</Button>
        </Col>
      </Row>

    </Form>


    {/* Location Modal Code */}
    <Modal isOpen={location_modal} toggle={locationtoggle} className={className} centered>
      <Form onSubmit={handleSubmit(onSubmitLocation)}>
        <ModalHeader toggle={locationtoggle}>Add New Location</ModalHeader>
        <ModalBody className="vint-vine-form">

          <FormGroup className="my-3">
            <Input type="text" name="name" id="location" placeholder="Location" className="text-dark" innerRef={register({
              required: true,
            })} />
            {_.get("name.type", errors) === "required" && (
              <p className="input-error text-danger">* Location required.</p>
            )}
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="skyblue">Add Location</Button>{' '}
          <Button color="secondary" onClick={locationtoggle}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>

    {/* wine type modal code */}
    <Modal isOpen={winetype_modal} toggle={wineTypetoggle} className={className} centered>
      <Form onSubmit={handleSubmit(onSubmitWineType)}>
        <ModalHeader toggle={wineTypetoggle}>Add New Wine Type</ModalHeader>
        <ModalBody className="vint-vine-form">

          <FormGroup className="my-3">
            <Input type="text" name="name" id="WineType" placeholder="Wine Type" className="text-dark" innerRef={register({
              required: true,
            })} />
            {_.get("name.type", errors) === "required" && (
              <p className="input-error text-danger">* Wine Type required.</p>
            )}
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="skyblue">Add Wine</Button>{' '}
          <Button color="secondary" onClick={wineTypetoggle}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>

  </>);
}

export default AddWine;
