import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import UpdatePassword from '../updatePassword';
import {
	ProSidebar,
	Menu,
	SubMenu,
	MenuItem,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from 'react-pro-sidebar';
import axios from 'axios';
import { config } from '../../components/Constant';
import 'react-pro-sidebar/dist/css/styles.css';

import {
	Logo,
	Dashboard,
	Inventory,
	Scan,
	Wine,
	Location,
	Reports,
	AddInventory,
	RemoveInventory,
	TransferInventory,
	ShowWine,
	Logout
} from '../../components/assets'

import {
	Container,
	Row,
	Col,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far)


export default function SideBar(props) {

	const [menuCollapse, setMenuCollapse] = useState(false)
	const [displayModal, setModal] = useState(false);
	const [display_sidebar, setSidebar] = useState(true)
	const toggleSidebar = () => setSidebar(!display_sidebar);
	const toggleModal = () => setModal(!displayModal);
	const menuIconClick = () => {
		menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
	};
	useEffect(() => {


		const handleCollapse = () => {
			setMenuCollapse(window.innerWidth <= 1600);
		}

		window.addEventListener("resize", handleCollapse);
		return () => {
			window.removeEventListener("resize", handleCollapse)
		};
	}, []);

	const logOut = () => {
		localStorage.removeItem('user_token');
		localStorage.removeItem('admin_token');
		localStorage.removeItem('user_name');
		window.location.href = "/login"
	}

	const backToAdmin = () => {
		localStorage.removeItem('user_token');
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "admin_name", {
			headers: {
				'Authorization': localStorage.getItem("admin_token")
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					localStorage.setItem("user_name", res.data.admin_name);
					window.location.href = "/users"
				}
			})
			.catch(function (error) { });
	}

	return (<>
		<ProSidebar collapsed={menuCollapse} className={display_sidebar ? "sidebar" : "sidebar open"}>
			<SidebarHeader className="position-relative">
				<div className="closemenu" onClick={menuIconClick}>
					{/* changing menu collapse icon on click */}
					{menuCollapse ? (
						<FontAwesomeIcon icon={['far', 'caret-square-right']} />
					) : (
						<FontAwesomeIcon icon={['far', 'caret-square-left']} />
					)}
				</div>

				<div className="logotext text-center py-5 px-3">
					{/* <p>{menuCollapse ? "Logo" : "Big Logo"}</p> */}
					<img src={Logo} alt="logo" className="img-fluid mt-3" />
				</div>
			</SidebarHeader>

			<SidebarContent>
				<Menu iconShape="square">
					<MenuItem icon={<img src={Dashboard} alt="Dashboard Icon" className="img-fluid" />}>Dashboard <NavLink to="/dashboard"></NavLink></MenuItem>

					<SubMenu title="Inventory" icon={<img src={Inventory} alt="Inventory Icon" className="img-fluid" />}>
						<MenuItem icon={<img src={AddInventory} alt="AddInventory Icon" className="img-fluid" />}>Add Inventory <NavLink to="/add-inventory"></NavLink></MenuItem>
						<MenuItem icon={<img src={RemoveInventory} alt="RemoveInventory Icon" className="img-fluid" />}>Remove Inventory <NavLink to="/remove-inventory"></NavLink></MenuItem>
						<MenuItem icon={<img src={TransferInventory} alt="TransferInventory Icon" className="img-fluid" />}>Transfer Inventory <NavLink to="/transfer-inventory"></NavLink></MenuItem>
					</SubMenu>

					<SubMenu title="Wine" icon={<img src={Wine} alt="Wine Icon" className="img-fluid" />}>
						<MenuItem icon={<img src={ShowWine} alt="Show Wine Icon" className="img-fluid" />}>Show Wine <NavLink to="/wines"></NavLink></MenuItem>
						<MenuItem icon={<img src={AddInventory} alt="Add New Wine Icon" className="img-fluid" />}>Add New Wine <NavLink to="/add-wine"></NavLink></MenuItem>
					</SubMenu>

					<MenuItem icon={<img src={Location} alt="Location Icon" className="img-fluid" />}>Location <NavLink to="/locations"></NavLink></MenuItem>

					<MenuItem icon={<img src={Reports} alt="Reports Icon" className="img-fluid" />}>Wine Type <NavLink to="/wine-types"></NavLink></MenuItem>
				</Menu>
			</SidebarContent>

			<SidebarFooter>
				<Menu iconShape="square">
					<MenuItem onClick={logOut} icon={<img src={Logout} alt="Logout Icon" className="img-fluid" />}>Logout</MenuItem>
				</Menu>
			</SidebarFooter>
		</ProSidebar>

		{/* Top Navigation */}
		<Container fluid className="dashboard-header py-4">

			<UpdatePassword parentRef={toggleModal.bind(this)} displayModal={displayModal} token={localStorage.getItem("user_token")} />

			<Row>
				<Col xs="6">
					<Row>
						<Col>
							<Button color="link" onClick={toggleSidebar} className="d-xl-none text-white outline-none">
								<FontAwesomeIcon icon="bars" />
							</Button>
						</Col>
					</Row>
				</Col>

				<Col xs="6" className="text-right">
					<UncontrolledDropdown>
						<DropdownToggle nav caret className="text-white d-flex align-items-center justify-content-end">
							<Avatar name={localStorage.getItem('user_name')} size="40" round={true} />
							<span className="d-inline-block text-left text-white ml-3">
								<p className="mb-0"><small>Hello,</small></p>
								<p className="mb-0">{localStorage.getItem('user_name')}</p>
							</span>
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem>
								<NavLink to={{
									pathname: "my-profile",
									state: { token: localStorage.getItem('user_token') }
								}} className="text-dark text-decoration-none"><FontAwesomeIcon icon="user-edit" className="mr-3" />My Profile</NavLink>
							</DropdownItem>
							{
								localStorage.getItem('admin_token') ?
									<DropdownItem >
										<NavLink to="#" onClick={backToAdmin} className="text-dark text-decoration-none"><FontAwesomeIcon icon="arrow-left" className="mr-3" />Back To Admin</NavLink>
									</DropdownItem> : ""
							}

							<DropdownItem >
								<NavLink to="#" onClick={toggleModal} className="text-dark text-decoration-none"><FontAwesomeIcon icon="unlock-alt" className="mr-3" />Change Password</NavLink>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={logOut}><FontAwesomeIcon icon="sign-out-alt" className="mr-3" />
								Logout
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

				</Col>
			</Row>
		</Container>
		{/* Top Navigation Ends */}

	</>);
}

// export default SideBar;
