import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import ForgetPassword from './pages/ForgetPassword';
import UserDashboard from './pages/user/Dashboard';
import AdminDashboard from './pages/admin/AdminDashboard';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/signup" component={SignUp}></Route>
          <Route exact path="/forget-password" component={ForgetPassword}></Route>
          <Route exact path="/reset-password" component={ResetPassword}></Route>


          {/* admin-routes */}
          <Route path="/users" render={props => <AdminDashboard path='users' {...props} />} exact />
          <Route path="/subscriptions" render={props => <AdminDashboard path='subscriptions' {...props} />} exact />
          <Route path="/profile" render={props => <AdminDashboard path='profile' {...props} />} exact />
          <Route path="/plans" render={props => <AdminDashboard path='plans' {...props} />} exact />

          {/* user-routes */}

          <Route path="/dashboard" render={props => <UserDashboard path='home' {...props} />} exact />
          <Route path="/locations" render={props => <UserDashboard path='location' {...props} />} exact />
          <Route path="/add-inventory" render={props => <UserDashboard path='add-inventory' {...props} />} exact />
          <Route path="/remove-inventory" render={props => <UserDashboard path='remove-inventory' {...props} />} exact />
          <Route path="/transfer-inventory" render={props => <UserDashboard path='transfer-inventory' {...props} />} exact />
          <Route path="/wines" render={props => <UserDashboard path='wines' {...props} />} exact />
          <Route path="/add-wine" render={props => <UserDashboard path='add-wine' {...props} />} exact />
          <Route path="/my-profile" render={props => <UserDashboard path='profile' {...props} />} exact />
          <Route path="/wine-types" render={props => <UserDashboard path='wine-type' {...props} />} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
