import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import PageTitle from '../components/pageTitle';
import axios from 'axios';
import { config } from '../components/Constant';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Row,
  Col, Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

const Profile = (props) => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });
  const [user, setUser] = useState({});

  const changeUser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  const onSubmit = () => {
    axios.put(config.url.REACT_APP_BACKEND_BASE_URL + "update_profile", { user: user }, {
      headers: {
        'Authorization': user.token
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          NotificationManager.success(res.data.message, 'Done', 2000);
          setUser(res.data.user)
        } else {
          NotificationManager.error(res.data.message, 'Error', 2000);
        }
      })
      .catch(function (error) { });
  }

  useEffect(() => {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "user_details", {
      headers: {
        'Authorization': props?.location?.state?.token
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setUser(res.data.user)
        }
      })
      .catch(function (error) { });
  }, [props])

  return (<>
    <NotificationContainer />
    <Row className="py-5">
      <Col>
        <PageTitle title="My Profile" />
      </Col>
    </Row>

    <Form onSubmit={handleSubmit(onSubmit)} className="vint-vine-form">
      <Row className="text-center text-lg-left d-flex align-items-center">
        <Col md="3">
          <Avatar name={user.name} size="200" round={true} />
        </Col>
        <Col md="6" className="text-white mt-4 mt-md-0">
          <FormGroup>
            <Input type="text" name="name" value={user.name} onChange={changeUser} placeholder="Name" innerRef={register({
              required: true,
            })} />
            {_.get("name.type", errors) === "required" && (
              <p className="input-error text-danger">* Name required.</p>
            )}
          </FormGroup>
          <FormGroup>
            <Input type="text" name="email" value={user.email} onChange={changeUser} placeholder="Email" innerRef={register({
              required: true,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
            })} />
            {_.get("email.type", errors) === "required" && (
              <p className="input-error text-danger">* Email required.</p>
            )}
            {_.get("email.type", errors) === "pattern" && (
              <label className="input-error text-danger">* Invalid Email.</label>
            )}
          </FormGroup>
          <FormGroup>
            <Input type="text" name="phone_no" value={user.phone_no} onChange={changeUser} placeholder="Phone" innerRef={register({
              required: true,
              minLength: 10
            })} />
            {_.get("phone_no.type", errors) === "required" && (
              <p className="input-error text-danger">* Name required.</p>
            )}
            {_.get("phone_no.type", errors) === "minLength" && (
              <p className="input-error text-danger">* Phone should be at least 10 characters.</p>
            )}
          </FormGroup>
          <FormGroup>
            <Button color="link">Update</Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  </>);
}

export default Profile;
