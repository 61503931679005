import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../components/Constant';
import PageTitle from '../../components/pageTitle';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
	Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup, Input
} from 'reactstrap'

import {
	AddRow,
	Delete
} from '../../components/assets';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fab, fas, far)

const WineType = () => {

	const { register, handleSubmit, errors } = useForm({
		mode: "onChange",
	});

	const [wine_types, setWineTypes] = useState([])
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [deleteModal, setdeleteModal] = useState(false);
	const [wineTypeId, setwineTypeId] = useState();
	const [editModal, seteditModal] = useState(false);
	const [name, setname] = useState('')

	useEffect(() => {
		getWineTypes();
	}, [])

	function getWineTypes() {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types", {
			headers: {
				'Authorization': localStorage.getItem('user_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					setWineTypes(res.data.wine_types)
				}
			})
			.catch(function (error) { });
	}

	const onSubmitWineType = (data, e) => {
		axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types", { wine_type: data }, {
			headers: {
				'Authorization': localStorage.getItem('user_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					getWineTypes();
					NotificationManager.success(res.data.message, 'Done', 1000);
				} else {
					NotificationManager.error(res.data.message, 'Error', 1000);
				}
				toggle();
			})
			.catch(function (error) { });
	}

	function toggleDelete(id) {
		if (!deleteModal) {
			setwineTypeId(id)
		}
		setdeleteModal(!deleteModal)
	}

	function toggleEdit(id, name) {
		if (!editModal) {
			setname(name)
			setwineTypeId(id)
		}
		seteditModal(!editModal)
	}

	function deleteWineType() {
		axios.delete(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types/" + wineTypeId, {
			headers: {
				'Authorization': localStorage.getItem('user_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					getWineTypes();
					setdeleteModal(!deleteModal);
					NotificationManager.success(res.data.message, 'Done', 1000);
				} else {
					NotificationManager.error(res.data.message, 'Error', 1000);
				}
			})
			.catch(function (error) { });
	}

	const onSubmitEditWineType = (data) => {
		axios.put(config.url.REACT_APP_BACKEND_BASE_URL + "wine_types/" + wineTypeId, { wine_type: data }, {
			headers: {
				'Authorization': localStorage.getItem('user_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					getWineTypes();
					NotificationManager.success(res.data.message, 'Done', 1000);
				} else {
					NotificationManager.error(res.data.message, 'Error', 1000);
				}
				toggleEdit();
			})
			.catch(function (error) { });
	}

	return (<>
		<NotificationContainer />
		<Row className="py-5">
			<Col xs="10">
				<PageTitle title="Add/Remove Wine Types" />
			</Col>

			<Col xs="2" className="text-right">
				<Button color="link" onClick={toggle}><img src={AddRow} alt="Add WineType" className="img-fluid mr-0" /></Button>
			</Col>
		</Row>

		<Row>
			<Col md="12">
				{
					wine_types.map(({ id, name }, i) => (
						<div key={id} className="location">
							<p className="mb-0 w-auto d-inline-block">{name}</p>

							<Button className="location-btn" color="primary" onClick={() => toggleEdit(id, name)}><FontAwesomeIcon icon="pen" /></Button>
							<Button className="location-btn" color="danger" onClick={() => toggleDelete(id)}><FontAwesomeIcon icon="trash" /></Button>
						</div>
					))
				}
			</Col>
		</Row>

		{/* Add WineType modal */}

		<Modal isOpen={modal} toggle={toggle} centered>
			<Form onSubmit={handleSubmit(onSubmitWineType)}>
				<ModalHeader toggle={toggle}>Add New WineType</ModalHeader>
				<ModalBody className="vint-vine-form">

					<FormGroup className="my-3">
						<Input type="text" name="name" placeholder="WineType" className="text-dark" innerRef={register({
							required: true,
						})} />
						{_.get("name.type", errors) === "required" && (
							<p className="input-error text-danger">* Wine Type required.</p>
						)}
					</FormGroup>

				</ModalBody>
				<ModalFooter>
					<Button color="skyblue">Add Wine Type</Button>{' '}
					<Button color="secondary" onClick={toggle}>Cancel</Button>
				</ModalFooter>
			</Form>
		</Modal>

		{/* Remove WineType Modal  */}

		<Modal isOpen={deleteModal} toggle={toggleDelete} centered>
			<Form>
				<ModalHeader toggle={toggleDelete}>Delete Wine Type</ModalHeader>
				<ModalBody className="vint-vine-form">
				</ModalBody>
				<ModalFooter>
					<Button color="skyblue" onClick={deleteWineType}>Delete Wine Type</Button>
					<Button color="danger" onClick={toggleDelete}>Cancel</Button>
				</ModalFooter>
			</Form>
		</Modal>

		{/* Edit WineTYpe modal */}

		<Modal isOpen={editModal} toggle={toggleEdit} centered>
			<Form onSubmit={handleSubmit(onSubmitEditWineType)}>
				<ModalHeader toggle={toggleEdit}>Edit Wine Type</ModalHeader>
				<ModalBody className="vint-vine-form">

					<FormGroup className="my-3">
						<Input type="text" name="name" value={name} onChange={(e) => setname(e.target.value)} className="text-dark" innerRef={register({
							required: true,
						})} />
						{_.get("name.type", errors) === "required" && (
							<p className="input-error text-danger">* Wine Type required.</p>
						)}
					</FormGroup>

				</ModalBody>
				<ModalFooter>
					<Button color="skyblue">Update</Button>
					<Button color="secondary" onClick={toggleEdit}>Cancel</Button>
				</ModalFooter>
			</Form>
		</Modal>

	</>);
}

export default WineType;