import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import {
	ProSidebar,
	Menu,
	SubMenu,
	MenuItem,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {
	Logo,
	Dashboard,
	TotalSellingPrice,
	Logout,
	Reports
} from '../../components/assets'
import {
	Container,
	Row,
	Col,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button
} from 'reactstrap';
import UpdatePassword from '../updatePassword';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far)

const SideBar = () => {

	const [menuCollapse, setMenuCollapse] = useState(false)
	const [displayModal, setModal] = useState(false);
	const [display_sidebar, setSidebar] = useState(true)
	const toggleSidebar = () => setSidebar(!display_sidebar);
	const toggleModal = () => setModal(!displayModal);
	const menuIconClick = () => {
		menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
	};

	useEffect(() => {

		const handleCollapse = () => {
			setMenuCollapse(window.innerWidth <= 1600);
		}

		window.addEventListener("resize", handleCollapse);
		return () => {
			window.removeEventListener("resize", handleCollapse)
		};
	}, []);

	const logOut = () => {
		localStorage.removeItem('admin_token');
		localStorage.removeItem('user_name');
		window.location.href = "/login"
	}

	return (<>
		<ProSidebar collapsed={menuCollapse} className={display_sidebar ? "sidebar" : "sidebar open"}>
			<SidebarHeader className="position-relative">
				<div className="closemenu" onClick={menuIconClick}>
					{/* changing menu collapse icon on click */}
					{menuCollapse ? (
						<FontAwesomeIcon icon={['far', 'caret-square-right']} />
					) : (
						<FontAwesomeIcon icon={['far', 'caret-square-left']} />
					)}
				</div>

				<div className="logotext text-center py-3 py-md-5 px-3">
					<img src={Logo} alt="logo" className="img-fluid mt-3" />
				</div>
			</SidebarHeader>

			<SidebarContent>
				<Menu iconShape="square">
					<MenuItem icon={<img src={Dashboard} alt="Dashboard Icon" className="img-fluid" />}>Users <NavLink to="/users"></NavLink></MenuItem>

					<MenuItem icon={<img src={TotalSellingPrice} alt="Location Icon" className="img-fluid" />}>Subscriptions <NavLink to="/subscriptions"></NavLink></MenuItem>

					<MenuItem icon={<img src={Reports} alt="Plan Icon" className="img-fluid" />}>Plans <NavLink to="/plans"></NavLink></MenuItem>

				</Menu>
			</SidebarContent>

			<SidebarFooter>
				<Menu iconShape="square">
					<MenuItem onClick={logOut} icon={<img src={Logout} alt="Logout Icon" className="img-fluid" />}>Logout</MenuItem>
				</Menu>
			</SidebarFooter>
		</ProSidebar>

		{/* Top Navigation */}
		<Container fluid className="dashboard-header py-4">

			<UpdatePassword parentRef={toggleModal.bind(this)} displayModal={displayModal} token={localStorage.getItem("admin_token")} />

			<Row>
				<Col xs="6">
					<Row>
						<Col>
							<Button color="link" onClick={toggleSidebar} className="d-xl-none text-white outline-none">
								<FontAwesomeIcon icon="bars" />
							</Button>
						</Col>
					</Row>
				</Col>

				<Col xs="6" className="text-right">
					<UncontrolledDropdown>
						<DropdownToggle nav caret className="text-white d-flex align-items-center justify-content-end">
							<Avatar name={localStorage.getItem('user_name')} size="40" round={true} />
							<span className="d-inline-block text-left text-white ml-3">
								<p className="mb-0"><small>Hello,</small></p>
								<p className="mb-0">{localStorage.getItem('user_name')}</p>
							</span>
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem>
								<NavLink to={{
									pathname: "profile",
									state: { token: localStorage.getItem('admin_token') }
								}} className="text-dark text-decoration-none"><FontAwesomeIcon icon="user-edit" className="mr-3" />My Profile</NavLink>
							</DropdownItem>

							<DropdownItem >
								<NavLink to="#" onClick={toggleModal} className="text-dark text-decoration-none"><FontAwesomeIcon icon="unlock-alt" className="mr-3" />Change Password</NavLink>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={logOut}><FontAwesomeIcon icon="sign-out-alt" className="mr-3" />
								Logout
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

				</Col>
			</Row>
		</Container>
		{/* Top Navigation Ends */}

	</>);
}

export default SideBar;
