import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageTitle from '../../components/pageTitle';
import {
	Row,
	Col,
	Table,
	Button
} from 'reactstrap';
import { config } from '../../components/Constant';


export default function Plan() {

	const [plans, setPlans] = useState([])

	useEffect(() => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "plans", {
			headers: {
				'Authorization': localStorage.getItem('admin_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					setPlans(res.data.plans)
				}
			})
			.catch(function (error) { });
	}, [])

	return (
		<>
			<Row className="py-5">
				<Col md="6" lg="8">
					<PageTitle title="Plans" />
				</Col>
			</Row>

			<Row>
				<Col md="12" className="show-wines text-center">
					<Table striped dark responsive borderless>
						<thead>
							<tr>
								<th>Name</th>
								<th>Amount</th>
								<th>Interval</th>
								<th>Stripe Plan ID</th>
							</tr>
						</thead>
						<tbody>
							{
								plans.map(({ name, amount, interval, id, stripe_plan_id }, index) => (
									<tr key={id}>
										<td>{name}</td>
										<td>$ {amount} / {interval}</td>
										<td>{interval}</td>
										<td>{stripe_plan_id}</td>
									</tr>
								))
							}
						</tbody>
					</Table>
				</Col>
			</Row>

		</>
	)
}
