import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import axios from 'axios';
import { config } from '../components/Constant';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Logo, LoginBtn
} from './../components/assets'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

const SignUp = () => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const history = useHistory();
  const [progress, setProgress] = useState(false);

  const onSubmit = (data, e) => {
    setProgress(true)
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "sign_up", { user: data })
      .then(res => {
        if (res.data.status === 200) {
          history.push({
            pathname: "/login",
            state: { message: res.data.message, status: "ok" }
          });
        } else {
          NotificationManager.error(res.data.message, 'Error');
          setProgress(false)
        }

      })
      .catch(function (error) { });
  }

  return (<>
    <NotificationContainer />
    <Container fluid className="accountation_page">
      <Container>
        <Row className="py-5">
          <Col>
            <NavLink to="/"><img src={Logo} alt="Logo" className="img-fluid" /></NavLink>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: '6', offset: '3' }} lg={{ size: '4', offset: '4' }} className="py-3 text-center">
            <h2>Welcome to VintVine</h2>

            <p>Your wine cellar is about to become much more impressive</p>

            <h5 className="mt-4 text-uppercase">Sign up</h5>

            <form onSubmit={handleSubmit(onSubmit)} className="form floating_label_input mt-4">
              <div className="field">
                <input
                  type="text"
                  placeholder=" "
                  name="name"
                  autoComplete="off"
                  ref={register({
                    required: true
                  })}
                />
                <label htmlFor="name" className={_.get("name.type", errors) ? "d-none" : ""}>Full Name</label>
                {_.get("name.type", errors) === "required" && (
                  <label className="input-error text-danger">* Name required.</label>
                )}
              </div>

              <div className="field">
                <input
                  type="text"
                  placeholder=" "
                  name="email"
                  autoComplete="off"
                  ref={register({
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                  })}
                /><label htmlFor="email" className={_.get("email.type", errors) ? "d-none" : ""}>Email</label>
                {_.get("email.type", errors) === "required" && (
                  <label className="input-error text-danger">* Email required.</label>
                )}
                {_.get("email.type", errors) === "pattern" && (
                  <label className="input-error text-danger">* Invalid Email.</label>
                )}
              </div>

              <div className="field">
                <input
                  type="password"
                  placeholder=" "
                  name="password"
                  autoComplete="off"
                  ref={register({
                    required: true,
                    minLength: 6
                  })}
                />
                <label htmlFor="password" className={_.get("password.type", errors) ? "d-none" : ""}>Password</label>
                {_.get("password.type", errors) === "required" && (
                  <label className="input-error text-danger">* Password required</label>
                )}
                {_.get("password.type", errors) === "minLength" && (
                  <label className="input-error text-danger">* Password should be minimum 6 digit</label>
                )}
              </div>

              <Row>
                <Col md="12" className="text-right login-btn mt-2">
                  <Button color="link" className="px-0">{progress ? "Progress" : "Get Started"} <img src={LoginBtn} alt="Flat Logo" className="img-fluid ml-2" /></Button>
                </Col>
                <Col md="12" className="text-center mt-3">
                  <p className="text-white"><small>By continuing, you agree to accept our <NavLink to="/privacy" className="text-white"><u>Privacy Policy</u></NavLink> & <NavLink to="/terms" className="text-white"><u>Terms of service</u></NavLink></small></p>
                </Col>

                <Col md="12" className="text-center mt-3 mt-md-5">
                  <h5><NavLink to="/login" className="text-white font-weight-normal">Already have an account? Login</NavLink></h5>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  </>);
}

export default SignUp;
