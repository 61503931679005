import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import axios from 'axios';
import { config } from '../components/Constant';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MainNavbar from '../components/MainNavbar'
import { NavLink } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Media,
  Form,
  FormGroup,
  Input
} from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import SectionTitle from '../components/sectionTitle';

library.add(fab, fas, far)

export default function Home() {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const [progress, setProgress] = useState(false);

  const onSubmit = (data, e) => {
    setProgress(true)
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "contact_us", { query: data })
      .then(res => {
        if (res.data.status === 200) {
          e.target.reset();
          NotificationManager.success(res.data.message, 'Done');
        } else {
          NotificationManager.error(res.data.message, 'Error');
        }
        setProgress(false)
      })
      .catch(function (error) { });
  }

  return (<>
    <NotificationContainer />
    <MainNavbar />

    {/* Banner */}
    <Container className="py-5 banner">
      <Row className="mt-md-5">
        <Col lg="6">
          <h1 className="mb-3 mb-md-5">The future of wine inventory is here.</h1>

          <NavLink to="/signup"><Button className="mr-4 mb-4 mb-md-0" color="pink">Start for Free</Button></NavLink>
        </Col>

        <Col lg="6">
        </Col>
      </Row>
    </Container>

    {/* Features */}
    <Container className="py-5 mt-lg-5 features" id="features">
      <Row className="mt-lg-5">
        <Col md="12" lg="4" className="mb-4 mb-lg-0">
          <SectionTitle title="Powerful Features" />
          <p>Restaurants and country clubs spend hours each month manually counting wine to take inventory. VintVine seamlessly automates this process and streamlines your cellar.</p>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="fingerprint" className="text-pink" />
            <h5>Biometric Login</h5>
            <p>Create multiple users and allow different levels of access using a quick fingerprint login.</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon={['far', 'thumbs-up']} className="text-pink" />
            <h5>Proven, Tested System</h5>
            <p>Because this was designed by servers and country club managers, it includes the most useful features keeping it clean and simple to use.</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="palette" className="text-pink" />
            <h5>Modern Design</h5>
            <p>Instead of relying on a default, outdated system used by most wine programs, we designed ours from scratch with customers in mind.</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="vector-square" className="text-pink" />
            <h5>Scalable</h5>
            <p>Whether you’re a private collector or a large, high-end country club, VintVine can adjust to your needs such as what data you want presented or how many cellars you’re managing.</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="route" className="text-pink" />
            <h5>Multiple Location Tracking</h5>
            <p>Track movement to and from your main cellar to other locations on property</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon={['far', 'bell']} className="text-pink" />
            <h5>Peak Time Notifications</h5>
            <p>Alert management and staff automatically when special wines are hitting their peak</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="chart-bar" className="text-pink" />
            <h5>Custom Reports</h5>
            <p>Generate reports based on which variable you hoose to avoid data overload</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="server" className="text-pink" />
            <h5>Meaningful Data</h5>
            <p>Know which wines are sold the most and sold the least to ensure higher profits</p>
          </div>
        </Col>

        <Col md="6" lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <FontAwesomeIcon icon="thermometer-half" className="text-pink" />
            <h5>Temperature Monitoring</h5>
            <p>Be able to monitor and adjust cellar temperatures remotely</p>
          </div>
        </Col>
      </Row>
    </Container>

    {/* How it Works */}
    <Container fluid className="bg-white py-5" id="howitworks">
      <Container>
        <Row>
          <Col md="12" className="text-center">
            <SectionTitle title="How it Works" className="dark" />
          </Col>
        </Row>

        <Row className="features mt-5">
          <Col md="10" className="mb-4 mb-md-5">
            <div className="dark-box">
              <Media className="align-items-center">
                <Media left>
                  <span className="big-text">1</span>
                </Media>
                <Media body className="pl-3 pl-md-5 text-white">
                  Once you sign up for VintVine, you can either upload your current wine inventory yourself, or we’ll do it for you at no cost. We do not have a spreadsheet upload feature due to different formats with different customers and wanted to create a more seamless experience for the customer.
                </Media>
              </Media>
            </div>
          </Col>

          <Col md={{ offset: '2', size: '10' }} className="mb-4 mb-md-5">
            <div className="dark-box">
              <Media className="align-items-center">
                <Media left>
                  <span className="big-text">2</span>
                </Media>
                <Media body className="pl-3 pl-md-5 text-white">
                  Once your inventory is uploaded, your inventory can be moved, updated and transferred between one or more locations
                </Media>
              </Media>
            </div>
          </Col>

          <Col md="10" className="mb-4 mb-md-5">
            <div className="dark-box">
              <Media className="align-items-center">
                <Media left>
                  <span className="big-text">3</span>
                </Media>
                <Media body className="pl-3 pl-md-5 text-white">
                  See your at-a-glance dashboard of your total wine inventory so your wine is updated in real time.
                </Media>
              </Media>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>

    {/* use cases */}
    <Container className="py-5 mt-lg-5 features" id="usecases">
      <Row>
        <Col md="12" className="mb-5">
          <SectionTitle title="Use Cases" />
        </Col>

        <Col lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <h5 className="mt-0">Cellar Optimization</h5>
            <p>VintVine allows users to create customized cellar locations to cover all movement within your business. Even if real-time reporting is not needed in the extra locations, tracking data is still maintained within the main cellar.</p>
          </div>
        </Col>

        <Col lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <h5 className="mt-0">Error Avoidance</h5>
            <p>There’s always the story of a server accidentally serving a thousand dollar bottle of wine. VintVine allows you to avoid that. For an extra layer of security where you think a staff member may accidentally remove the wine without scanning it, an iBeacon can be installed for an automatic detection alert and prompt for when the wine is removed from its location.</p>
          </div>
        </Col>

        <Col lg="4" className="mb-4 mb-md-5">
          <div className="features-box">
            <h5 className="mt-0">Sales Analytics</h5>
            <p>VintVine’s dashboard and reports allows you to know what’s sitting in the cellar with no movement. Having this data at your fingertips allows managers and owners to ensure maximum profitability by keeping and selling only which sells the most.</p>
          </div>
        </Col>

      </Row>
    </Container>

    {/* find out more */}
    <Container className="py-lg-5 mt-lg-5 contact">
      <Row>
        <Col md="12" className="mb-5">
          <SectionTitle title="Find out More" />
        </Col>

        <Col lg="5" className="mb-4 mb-md-5">
          {/* <h3><FontAwesomeIcon icon="phone-alt" className="text-blue mr-4" /><a href="tel:(512)378-3873">(512) 378-3873</a></h3> */}
          <h3><FontAwesomeIcon icon="envelope" className="text-blue mr-4" /><a href="mailto:info@vintvine.com">info@vintvine.com</a></h3>
        </Col>

        <Col lg="7" className="mb-4 mb-md-5">
          <div className="vint-vine-form dark-box p-md-5">
            <h3 className="mb-4 text-white">Send us a message</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  autoComplete="off"
                  innerRef={register({
                    required: true
                  })}
                />
                {_.get("name.type", errors) === "required" && (
                  <label className="input-error text-danger">* Name required.</label>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  innerRef={register({
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
                  })}
                />
                {_.get("email.type", errors) === "required" && (
                  <label className="input-error text-danger">* Email required.</label>
                )}
                {_.get("email.type", errors) === "pattern" && (
                  <label className="input-error text-danger">* Invalid Email.</label>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Inquiry Reason"
                  name="subject"
                  autoComplete="off"
                  innerRef={register({
                    required: true
                  })}
                />
                {_.get("subject.type", errors) === "required" && (
                  <label className="input-error text-danger">* Write you Inquiry concern.</label>
                )}              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Message"
                  name="message"
                  autoComplete="off"
                  innerRef={register({
                    required: true
                  })}
                />
                {_.get("message.type", errors) === "required" && (
                  <label className="input-error text-danger">* Give us short detail.</label>
                )}              </FormGroup>
              <Button color="skyblue" block>{progress ? "Progress" : "Submit"}</Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>

    {/* Copyright */}
    <Container fluid className="py-4 copyright">
      <Container>
        <Row>
          <Col>
            <p>Copyright © 2022</p>
          </Col>
        </Row>
      </Container>
    </Container>

  </>)
}
