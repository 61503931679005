import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "white",
            fontSize: "20px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "white",
            },
        },
        invalid: {
            color: "#e5424d",
            ":focus": {
                color: "#fff",
            },
        },
    },
    hidePostalCode: true,
};

export default function CardSection() {
    return (
        <CardElement options={CARD_ELEMENT_OPTIONS} className="form-control" />
    );
}