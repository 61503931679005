import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { config } from '../components/Constant';
import _ from "lodash/fp";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
	FlatLogo,
	LoginBtn
} from './../components/assets'

import {
	Container,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
	Input
} from 'reactstrap';

export default function ForgetPassword() {

	const { register, handleSubmit, errors } = useForm({
		mode: "onChange",
	});
	const [progress, setProgress] = useState(false);

	const onSubmit = (data, e) => {
		setProgress(true)
		axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "forget_password", { user: data })
			.then(res => {
				if (res.data.status === 200) {
					NotificationManager.success(res.data.message, 'Done');
					e.target.reset();
				} else {
					NotificationManager.error(res.data.message, 'Error');
				}
				setProgress(false)
			})
			.catch(function (error) { });
	}

	return (<>
		<NotificationContainer />
		<Container fluid className="accountation_page">
			<Container>
				<Row className="py-5">
					<Col>
						<NavLink to="/login"><img src={FlatLogo} alt="Flat Logo" className="img-fluid" /></NavLink>
					</Col>
				</Row>

				<Row>
					<Col md={{ size: '6', offset: '3' }} lg={{ size: '4', offset: '4' }} className="py-3 py-md-5 text-center">
						<h5 className="mt-4 text-uppercase">Forget Password</h5>

						<form onSubmit={handleSubmit(onSubmit)} className="form floating_label_input mt-4">
							<div className="field">
								<Input
									type="text"
									placeholder="Email"
									name="email"
									autoComplete="off"
									innerRef={register({
										required: true,
										pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
									})}
								/>
								<label htmlFor="email" className={_.get("email.type", errors) ? "d-none" : ""}>Email</label>
								{_.get("email.type", errors) === "required" && (
									<label className="input-error text-danger">* Email required.</label>
								)}
								{_.get("email.type", errors) === "pattern" && (
									<label className="input-error text-danger">* Invalid Email.</label>
								)}
							</div>
							<Row>
								<Col md="12" className="text-right login-btn mt-2">
									<Button color="link" className="px-0">{progress ? "Progress" : "Submit"} <img src={LoginBtn} alt="Flat Logo" className="img-fluid ml-2" /></Button>
								</Col>
							</Row>
						</form>
					</Col>
				</Row>
			</Container>
		</Container>
	</>)
}