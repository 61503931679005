import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import axios from 'axios';
import { config } from '../components/Constant';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Logo,
  LoginBtn
} from './../components/assets'

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

const Login = (props) => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data, e) => {
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "login", { user: data })
      .then(res => {
        if (res.data.status === 200) {
          if (res.data.user.is_admin === true) {
            localStorage.setItem("admin_token", res.data.user.token);
            window.location.href = "/users"
          } else {
            localStorage.setItem("user_token", res.data.user.token);
            window.location.href = "/dashboard"
          }
          localStorage.setItem("user_name", res.data.user.name);
        } else {
          NotificationManager.error(res.data.message, 'Error');
        }
      })
      .catch(function (error) { });
  }


  useEffect(() => {
    if (props?.location?.state?.status === "ok") {
      NotificationManager.success(props?.location?.state?.message, 'Done');
    } else if (props?.location?.state?.status === "error") {
      NotificationManager.error(props?.location?.state?.message, 'Error');
    }

    if (localStorage.getItem("user_token") !== null && localStorage.getItem("admin_token") === null) {
      window.location.href = "/dashboard";
    } else if (localStorage.getItem("admin_token") !== null && localStorage.getItem("user_token") !== null) {
      window.location.href = "/dashboard";
    } else if (localStorage.getItem("admin_token") !== null) {
      window.location.href = "/users";
    }

  }, [])

  return (<>
    <NotificationContainer />
    <Container fluid className="accountation_page">
      <Container>
        <Row className="py-5">
          <Col>
            <NavLink to="/"><img src={Logo} alt="Logo" className="img-fluid" /></NavLink>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: '6', offset: '3' }} lg={{ size: '4', offset: '4' }} className="py-3 py-md-5 text-center">
            <h2>Welcome Back</h2>

            <h5 className="mt-4 text-uppercase">Login</h5>

            <form onSubmit={handleSubmit(onSubmit)} className="form floating_label_input mt-4">
              <div className="field">
                <input
                  type="text"
                  placeholder=" "
                  name="email"
                  autoComplete="off"
                  ref={register({
                    required: true,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                  })}
                /><label htmlFor="email" className={_.get("email.type", errors) ? "d-none" : ""}>Email</label>
                {_.get("email.type", errors) === "required" && (
                  <label className="input-error text-danger">* Email required.</label>
                )}
                {_.get("email.type", errors) === "pattern" && (
                  <label className="input-error text-danger">* Invalid Email.</label>
                )}
              </div>

              <div className="field">
                <input
                  type="password"
                  placeholder=" "
                  name="password"
                  autoComplete="off"
                  ref={register({
                    required: true
                  })}
                /><label htmlFor="password" className={_.get("password.type", errors) ? "d-none" : ""}>Password</label>
                {_.get("password.type", errors) === "required" && (
                  <label className="input-error text-danger">* Password required</label>
                )}
              </div>

              <Row>
                <Col md="12" className="text-right">
                  <p><NavLink to="/forget-password" className="text-white font-weight-normal">Forgot Password?</NavLink></p>
                </Col>

                <Col md="12" className="text-right login-btn mt-2">
                  <Button color="link" className="px-0">Login <img src={LoginBtn} alt="Flat Logo" className="img-fluid ml-2" /></Button>
                </Col>

                <Col md="12" className="text-center mt-3 mt-md-5">
                  <h5><NavLink to="/signup" className="text-white font-weight-normal">Don't have account? Signup</NavLink></h5>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  </>);
}

export default Login;
