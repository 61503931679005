import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import PageTitle from '../../components/pageTitle';
import {
	Row,
	Col,
	Table,
	Button
} from 'reactstrap';
import ReactPaginate from "react-paginate";
import { config } from '../../components/Constant';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fab, fas, far)

const Subscription = () => {

	const [subscriptions, setSubscriptions] = useState([]);
	const [offset, setOffset] = useState(0);
	const [per_page] = useState(10);
	const [page_count, setPageCount] = useState(0);
	const [selectedPge, setselectedPge] = useState(0)

	useEffect(() => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "subscriptions_list", {
			headers: {
				'Authorization': localStorage.getItem('admin_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					setSubscriptions(res.data.subscriptions)
				}
			})
			.catch(function (error) { });
	}, [])

	useEffect(() => {
		var count = Math.ceil(subscriptions.length / per_page);
		setPageCount(count);
	}, [subscriptions]);

	function handlePageClick(e) {
		const selectedPage = e.selected;
		setselectedPge(selectedPage)
		const offset = selectedPage * per_page;
		setOffset(offset);
	}


	return (
		<>
			<Row className="py-5">
				<Col md="6" lg="8">
					<PageTitle title="Subscriptions" />
				</Col>
			</Row>

			<Row>
				<Col md="12" className="show-wines text-center">
					<Table striped dark responsive borderless>
						<thead>
							<tr>
								<th>Email</th>
								<th>Plan</th>
								<th>Amount</th>
								<th>Subscription Period</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{
								subscriptions.slice(offset, per_page + offset).map(({ amount, email, status, id, interval, plan, start_date, end_date }, index) => (
									<tr key={id}>
										<td>{email}</td>
										<td>{plan}</td>
										<td>{amount}/{interval}</td>
										<td>{start_date} - {end_date}</td>
										<td><span className={status === "Active" ? "p-2 badge-success" : "p-2 badge-danger"}>{status}</span></td>
									</tr>
								))
							}
						</tbody>
					</Table>
				</Col>

				<Col md="12" className="show-wines text-center">
					<ReactPaginate
						previousLabel={"‹"}
						nextLabel={"›"}
						breakLabel={"..."}
						breakClassName={"break-me"}
						pageCount={page_count}
						marginPagesDisplayed={3}
						pageRangeDisplayed={3}
						onPageChange={handlePageClick}
						containerClassName={"pagination mt-4 justify-content-center"}
						activeClassName={"active"}
					/>
				</Col>
			</Row>
		</>
	);
}

export default Subscription;
