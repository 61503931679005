import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DebounceInput } from 'react-debounce-input';
import { config } from '../../../components/Constant';
import PageTitle from '../../../components/pageTitle';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Table
} from 'reactstrap';

const Add = () => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const [search, setSearch] = useState("");
  const [wineInput, setWineInput] = useState("");
  const [wines, setWines] = useState([]);
  const [selectLocation, setselectLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [wineId, setWineId] = useState('');

  useEffect(() => {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "search_inventory?search=" + search, {
      headers: {
        'Authorization': localStorage.getItem("user_token")
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setWines(res.data.wines)
        } else {
          setWines([]);
        }
      })
      .catch(function (error) { });

  }, [search]);

  const _onChange = (value) => {
    setSearch(value)
    setWineInput(value)
  }

  const selecteWine = (id, name, location_id) => {
    setWineInput(name)
    setselectLocation(location_id)
    setWines([]);
    setWineId(id)
  }

  useEffect(() => {
    getLocatons();
  }, [])

  function getLocatons() {
    axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "locations", {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          setLocations(res.data.locations)
        }
      })
      .catch(function (error) { });
  }

  const onSubmit = (data, e) => {
    axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "add_inventory", { wine: data, location_id: selectLocation, wine_id: wineId }, {
      headers: {
        'Authorization': localStorage.getItem('user_token')
      }
    })
      .then(res => {
        if (res.data.status === 200) {
          e.target.reset();
          NotificationManager.success(res.data.message, 'Done', 2000);
        } else {
          NotificationManager.error(res.data.message, 'Error', 2000);
        }
      })
      .catch(function (error) { });
  }

  const checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
        ? event.which
        : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }

  const renderSearchData = () => {
    return (
      <Table striped dark responsive borderless>
        <thead>
          <tr>
            <th>UPC</th>
            <th>Name</th>
            <th>Type</th>
            <th>Quantity</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {
            wines.map(({ upc, name, quantity, wine_type, location, id, location_id }, index) => (
              <tr key={id} onClick={() => selecteWine(id, name, location_id)} class="cursor-pointer">
                <td>{upc}</td>
                <td>{name}</td>
                <td>{wine_type}</td>
                <td>{quantity}</td>
                <td>{location}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    )
  }

  return (<>
    <NotificationContainer />
    <Row className="py-5">
      <Col>
        <PageTitle title="Add inventory" />
      </Col>
    </Row>

    <Form onSubmit={handleSubmit(onSubmit)} className="vint-vine-form">
      <Row>
        <Col md="12">
          <FormGroup>
            <DebounceInput
              name="upc" placeholder="Enter UPC / EAN / Name"
              type="text"
              autoComplete="off"
              minLength={1}
              value={wineInput}
              className="form-control"
              debounceTimeout={300}
              onChange={(e) => _onChange(e.target.value)}
            />
            <div>
              {
                wines.length > 0 ? renderSearchData() : null
              }
            </div>
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <Input type="text" name="quantity" onKeyPress={checkNumeric} id="quantity" placeholder="Quantity" innerRef={register({
              required: true,
            })} />
            {_.get("quantity.type", errors) === "required" && (
              <p className="input-error text-danger">* Quantity required.</p>
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup>
            <select className="form-control" value={selectLocation} onChange={e => setselectLocation(e.target.value)}>
              <option value="">Select Location</option>
              {locations.map((item, i) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </FormGroup>
        </Col>

      </Row>

      <Row>
        <Col md="12" className="text-right mt-3 mt-md-5">
          <Button type="submit" size="lg" color="link">Add Inventory</Button>
        </Col>
      </Row>
    </Form>

  </>);
}

export default Add;
