import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SideBar from './AdminSidebar';
import { Container } from 'reactstrap';
import Users from './Users';
import Subscriptions from './Subscription';
import Profile from '../Profile';
import Plan from '../admin/Plan';


const AdminDashboard = (props) => {

    const history = useHistory();

    useEffect(() => {
        isLogin()
    }, []);

    const isLogin = () => {
        if (localStorage.getItem("admin_token") === null) {
            history.push({
                pathname: "/login",
                state: { message: 'Please Login First!', status: 'error' }
            });
        }
    }

    const renderSwitch = () => {
        switch (props.path) {
            case 'subscriptions':
                return <Subscriptions {...props} />
            case 'profile':
                return <Profile {...props} />
            case 'plans':
                return <Plan {...props} />
            default:
                return <Users />
        }
    }

    return (<>
        <SideBar />

        <Container>
            {renderSwitch()}
        </Container>

    </>);
}

export default AdminDashboard;
