import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SideBar from './UserSidebar';
import { Container, Row, Col } from 'reactstrap';
import UserHome from './UserHome';
import Location from './Location';
import Wines from './wines/ShowWines';
import AddWine from './wines/AddWine';
import WineType from './WineType';
import AddInventory from './inventory/Add';
import RemoveInventory from './inventory/Remove';
import TransferInventory from './inventory/Transfer';
import MyProfile from '../Profile';


const Dashboard = (props) => {

	const history = useHistory();

	useEffect(() => {
		isLogin()
	}, []);

	const isLogin = () => {
		if (localStorage.getItem("user_token") === null) {
			history.push({
				pathname: "/login",
				state: { message: 'Please Login First!', status: 'error' }
			});
		}
	}

	const renderSwitch = () => {
		switch (props.path) {
			case 'location':
				return <Location {...props} />
			case 'wine-type':
				return <WineType {...props} />
			case 'add-inventory':
				return <AddInventory {...props} />
			case 'remove-inventory':
				return <RemoveInventory {...props} />
			case 'transfer-inventory':
				return <TransferInventory {...props} />
			case 'wines':
				return <Wines {...props} />
			case 'add-wine':
				return <AddWine {...props} />
			case 'profile':
				return <MyProfile {...props} />
			default:
				return <UserHome />
		}
	}

	return (<>
		<SideBar />

		<Container>
			{renderSwitch()}
		</Container>

	</>);
}

export default Dashboard;
