const prod = {
	url: {
		DOMAIN_URL: 'https://api.vintvine.com',
		REACT_APP_BACKEND_BASE_URL: 'https://api.vintvine.com/api/',
		REACT_APP_STRIPE_KEY: 'pk_live_51Ig5SvHI8T3cNEWPqTckRtGzwxgv7N9mMMKL8bSzm7QqOzeyB15C7tgXYtxawHU3QQUvT99Ls5ZoBdOqRLVLGhoo00t7jK5Kqf'
	}
};

const dev = {
	url: {
		DOMAIN_URL: 'http://localhost:3002',
		REACT_APP_BACKEND_BASE_URL: 'http://localhost:3002/api/',
		REACT_APP_STRIPE_KEY: 'pk_test_51Ig2I5Feip1Bv8ImGDQfhMfgGV9wO9v6xswCsjPb88YIhwT9YbuyTQFa8qQyx8TJMIlSPmLWIZ9skQ6EByaByC0l00KHRZ76s9'
	}
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod