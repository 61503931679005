import React, { useState, useEffect } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import CardSection from './CardSection';
import { config } from '../../../components/Constant';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function PaymentForm(props) {

	const [modal, setModal] = useState(true);
	const toggle = () => setModal(!modal);
	const [monthly_plan, setMonthlyPlan] = useState('')
	const [yearly_plan, setYearlyPlan] = useState('')
	const [selectPlan, setselectPlan] = useState('')
	const [error, setError] = useState("")
	const [progress, setProgress] = useState(false)
	const [load, setLoad] = useState(false)

	const { handleSubmit, errors } = useForm({
		mode: "onChange",
	});

	const onSubmit = (data, e) => {
		setProgress(true)
		handleForm(e, data);
	};

	const getPlans = () => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "plans")
			.then(res => {
				if (res.data.status === 200) {
					for (let index = 0; index < res.data.plans.length; index++) {
						if (res.data.plans[index].interval === "month") {
							setMonthlyPlan(res.data.plans[index].stripe_plan_id)
							setselectPlan(res.data.plans[index].stripe_plan_id)
						} else if (res.data.plans[index].interval === "year") {
							setYearlyPlan(res.data.plans[index].stripe_plan_id)
						}
					}
				} else {
				}
			})
			.catch(function (error) { });
	}

	const handlePlan = (e) => { setselectPlan(e.target.value) }

	const handleForm = async (e, data) => {
		const { stripe, elements } = props;
		if (!stripe || !elements) {
			return;
		}
		const card = elements.getElement(CardElement);
		const result = await stripe.createToken(card);
		if (result.error) {
			setError(result.error.message)
			setProgress(false)
		} else {
			setError("")
			createToken(result.token.id)
		}
	}

	const createToken = (token) => {
		axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "subscriptions", { token: token, plan_id: selectPlan }, {
			headers: {
				'Authorization': localStorage.getItem("user_token")
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					NotificationManager.success(res.data.message, 'Subscribed', 2000);
					setModal(false);
				} else {
					NotificationManager.error(res.data.message, 'Error', 2000);
				}
				setProgress(false)
			})
			.catch(function (error) { });
	}

	const getUserDetails = () => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "user_details", {
			headers: {
				'Authorization': localStorage.getItem("user_token")
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					if (localStorage.getItem('admin_token') !== null) {
						setModal(false)
					} else {
						if (res.data.user.is_trial === true) {
							setModal(false)
						}
						else if (res.data.user.subscription !== '' && res.data.user.subscription.status === 'Active' && res.data.user.subscription.active === true) {
							setModal(false)
						} else {
							setModal(true)
						}
					}
				}
				setLoad(true)
			})
			.catch(function (error) { });
	}

	useEffect(() => {
		getUserDetails()
		getPlans()
	}, []);

	return (
		<>
			<NotificationContainer />
			{
				load ?
					<Modal isOpen={modal} toggle={toggle} centered className="select-plan-box">
						<ModalHeader toggle={toggle}>Choose Subscription</ModalHeader>
						<Form className="payment-form" onSubmit={handleSubmit(onSubmit)}>
							<ModalBody className="text-center">
								<h5>We offer monthly or annual subscription plan.</h5>
								<h5 className="mb-4"><strong>Save $80 Annually.</strong></h5>
								<Row className="select-plan mb-4">
									<Col xs="6" sm={{ size: '4', offset: '2' }}>
										<FormGroup check>
											<Label check>
												Monthly
										<h3>$39.99</h3>
												<Input type="radio" name="subscription" value={monthly_plan} checked={selectPlan === monthly_plan} onChange={handlePlan} />
												<span className="checkmark"></span>
											</Label>
										</FormGroup>
									</Col>

									<Col xs="6" sm="4">
										<FormGroup check>
											<Label check>
												Yearly
										<h3>$399.99</h3>
												<Input type="radio" name="subscription" value={yearly_plan} checked={selectPlan === yearly_plan} onChange={handlePlan} />
												<span className="checkmark"></span>
											</Label>
										</FormGroup>
									</Col>
								</Row>
								<CardSection />
								{error !== "" ? <p className="input-error text-danger">{error}</p> : null}
							</ModalBody>
							<ModalFooter>
								<Button color="gray-500">{progress ? 'Progress' : 'Subscribe'}</Button>
							</ModalFooter>
						</Form>
					</Modal>
					: null
			}

		</>
	)
}

export default function InjectedCheckoutForm(props) {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<PaymentForm stripe={stripe} elements={elements} />
			)}
		</ElementsConsumer>
	);
}