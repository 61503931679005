import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { config } from '../components/Constant';
import _ from "lodash/fp";
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import {
	FlatLogo,
	LoginBtn
} from './../components/assets'

const ResetPassword = (props) => {

	const { register, handleSubmit, errors, watch } = useForm({
		mode: "onChange",
	});
	const history = useHistory();
	const password = useRef({});
	password.current = watch("new_password", "");
	const [password_token, setPasswordToken] = useState('');

	useEffect(() => {
		if (props?.location?.search) {
			let token = queryString.parse(props?.location?.search);
			if (token?.reset_token) {
				setPasswordToken(token?.reset_token)
			} else {
				history.push({
					pathname: "/login",
					state: { message: 'You are not Authorize User!', status: "error" }
				});
			}
		} else {
			history.push({
				pathname: "/login",
				state: { message: 'You are not Authorize User!', status: "error" }
			});
		}
	}, []);

	const onSubmit = (data, e) => {
		axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "reset_password", { user: data }, {
			headers: {
				'Authorization': password_token
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					history.push({
						pathname: "/login",
						state: { message: res.data.message, status: "ok" }
					});
				} else if (res.data.status === 401) {
					NotificationManager.error(res.data.error, 'Error');
				} else {
					NotificationManager.error(res.data.message, 'Error');
				}
			})
			.catch(function (error) { });
	}


	return (
		<>
			<NotificationContainer />
			<Container fluid className="accountation_page">
				<Container>
					<Row className="py-5">
						<Col>
							<NavLink to="/login"><img src={FlatLogo} alt="Flat Logo" className="img-fluid" /></NavLink>
						</Col>
					</Row>

					<Row>
						<Col md={{ size: '6', offset: '3' }} lg={{ size: '4', offset: '4' }} className="py-3 py-md-5 text-center">
							<h5 className="mt-4 text-uppercase">Reset Password</h5>

							<form onSubmit={handleSubmit(onSubmit)} className="form floating_label_input mt-4">
								<div className="field">
									<Input
										type="password"
										placeholder="password"
										name="new_password"
										autoComplete="off"
										innerRef={register({
											required: true,
											minLength: 6
										})}
									/>
									<label htmlFor="new_password" className={_.get("new_password.type", errors) ? "d-none" : ""}>New Password</label>
									{_.get("new_password.type", errors) === "required" && (
										<label className="input-error text-danger">* Password required.</label>
									)}
									{_.get("new_password.type", errors) === "minLength" && (
										<label className="input-error text-danger">* Password should be minimum 6 digit.</label>
									)}
								</div>
								<div className="field">
									<Input
										type="password"
										placeholder="confirm password"
										name="confirm_password"
										autoComplete="off"
										innerRef={register({
											validate: (value) =>
												value === password.current || "* The passwords do not match"
										})}
									/>
									<label htmlFor="confirm_password" className={_.get("confirm_password.type", errors) ? "d-none" : ""}>Confirm Password</label>
									{errors.confirm_password && <label className="input-error text-danger">{errors.confirm_password.message}</label>}
								</div>
								<Row>
									<Col md="12" className="text-right login-btn mt-2">
										<Button color="link" className="px-0">Submit<img src={LoginBtn} alt="Flat Logo" className="img-fluid ml-2" /></Button>
									</Col>
								</Row>
							</form>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
}

export default ResetPassword;
