import React, { useRef, useState, useEffect } from 'react';
import {
	Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input
} from 'reactstrap';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import axios from 'axios';
import { config } from '../components/Constant';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function UpdatePassword(props) {
	const { register, handleSubmit, errors, watch } = useForm({
		mode: "onChange",
	});

	const password = useRef({});
	password.current = watch("new_password", "");

	const onSubmit = (data) => {
		axios.post(config.url.REACT_APP_BACKEND_BASE_URL + "update_password", { user: data }, {
			headers: {
				'Authorization': props.token
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					NotificationManager.success(res.data.message, 'Done', 2000);
					props.parentRef();
				} else {
					NotificationManager.error(res.data.message, 'Error', 2000);
				}
			})
			.catch(function (error) { });

	}

	return (
		<>
			<NotificationContainer />
			<Modal isOpen={props.displayModal} toggle={props.parentRef}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ModalHeader toggle={props.parentRef}>Change Password</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Input
								type="password"
								placeholder="Old Password"
								name="old_password"
								autoComplete="new-password"
								innerRef={register({
									required: true,
									minLength: 6
								})}
							/>
							{_.get("old_password.type", errors) === "required" && (
								<p className="input-error text-danger">* Old Password required</p>
							)}
							{_.get("old_password.type", errors) === "minLength" && (
								<p className="input-error text-danger">* Password should be minimum 6 digit</p>
							)}
						</FormGroup>
						<FormGroup>
							<Input
								type="password"
								placeholder="New Password"
								name="new_password"
								autoComplete="off"
								innerRef={register({
									required: true,
									minLength: 6
								})}
							/>
							{_.get("new_password.type", errors) === "required" && (
								<p className="input-error text-danger">* Password required</p>
							)}
							{_.get("new_password.type", errors) === "minLength" && (
								<p className="input-error text-danger">* Password should be minimum 6 digit</p>
							)}
						</FormGroup>

						<FormGroup>
							<Input
								type="password"
								placeholder="Confirm Password"
								name="confirm_password"
								autoComplete="off"
								innerRef={register({
									validate: (value) =>
										value === password.current || "* The passwords do not match"
								})}
							/>
							{errors.confirm_password && <p className="input-error text-danger">{errors.confirm_password.message}</p>}
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color="primary">Update</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</>
	)
}