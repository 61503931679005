import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import PageTitle from '../../../components/pageTitle';
import {
	Row,
	Col,
	Table,
	Input
} from 'reactstrap';
import { useSortableData } from '../../../components/SortTable';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { DebounceInput } from 'react-debounce-input';
import { ExportCSV } from '../../../components/assets';
import ReactPaginate from "react-paginate";
import { config } from '../../../components/Constant';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fab, fas, far)


const ShowWines = () => {

	const [Wines, setWines] = useState([]);
	const [search, setSearch] = useState('');
	const [offset, setOffset] = useState(0);
	const [per_page] = useState(10);
	const [page_count, setPageCount] = useState(0);
	const [selectedPge, setselectedPge] = useState(0)

	const { items, requestSort, sortConfig } = useSortableData(Wines);

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	useEffect(() => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "wines", {
			headers: {
				'Authorization': localStorage.getItem('user_token')
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					setWines(res.data.wines)
				} else {
					NotificationManager.error(res.data.message, 'Error', 2000);
				}
			})
			.catch(function (error) { });
	}, [])

	useEffect(() => {
		var count = Math.ceil(Wines.length / per_page);
		setPageCount(count);
	}, [Wines]);

	function handlePageClick(e) {
		const selectedPage = e.selected;
		setselectedPge(selectedPage)
		const offset = selectedPage * per_page;
		setOffset(offset);
	}

	useEffect(() => {
		axios.get(config.url.REACT_APP_BACKEND_BASE_URL + "wines?search=" + search, {
			headers: {
				'Authorization': localStorage.getItem("user_token")
			}
		})
			.then(res => {
				if (res.data.status === 200) {
					setWines(res.data.wines)
					setOffset(0)
					if (search === '') {
						setOffset(selectedPge * per_page);
					}
				}
			})
			.catch(function (error) { });

	}, [search]);

	return (<>
		<NotificationContainer />
		<Row className="py-5">
			<Col md="6" lg="8">
				<PageTitle title="Show Wines" />
			</Col>

			<Col md="6" lg="4">
				<div className="search-bar mt-3 mt-md-0">
					<FontAwesomeIcon icon="search" />
					<DebounceInput
						type="search"
						minLength={1}
						placeholder={"Search Wine"}
						debounceTimeout={300}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</Col>

			<Col md="12" className="text-right mt-4">
				<CSVLink className="text-decoration-none export-csv-btn" filename={"WinesList.csv"} data={Wines}><img src={ExportCSV} alt="Export CSV" className="img-fluid" /> Export CSV</CSVLink>
			</Col>
		</Row>

		<Row>
			<Col md="12" className="show-wines text-center">
				<Table striped dark responsive borderless>
					<thead>
						<tr className="sort-table">
							<th onClick={() => requestSort('upc')}
								className={getClassNamesFor('upc')}>UPC</th>
							<th onClick={() => requestSort('wine_type')}
								className={getClassNamesFor('wine_type')}>Type</th>
							<th onClick={() => requestSort('name')}
								className={getClassNamesFor('name')}>Name</th>
							<th onClick={() => requestSort('year')}
								className={getClassNamesFor('year')}>Vintage</th>
							<th onClick={() => requestSort('cost')}
								className={getClassNamesFor('cost')}>Cost</th>
							<th onClick={() => requestSort('price')}
								className={getClassNamesFor('price')}>Selling Price</th>
							<th onClick={() => requestSort('quantity')}
								className={getClassNamesFor('quantity')}>Total Bottles</th>
							<th onClick={() => requestSort('location')}
								className={getClassNamesFor('location')}>Location</th>
						</tr>
					</thead>
					<tbody>
						{
							items.slice(offset, per_page + offset).map(({ upc, name, year, cost, price, quantity, wine_type, location, id }, index) => (
								<tr key={id}>
									<td>{upc}</td>
									<td>{wine_type}</td>
									<td>{name}</td>
									<td>{year}</td>
									<td>{cost}</td>
									<td>{price}</td>
									<td>{quantity}</td>
									<td>{location}</td>
								</tr>
							))
						}

					</tbody>
				</Table>
			</Col>

			<Col md="12" className="show-wines text-center">
				<ReactPaginate
					previousLabel={"‹"}
					nextLabel={"›"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={page_count}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={handlePageClick}
					containerClassName={"pagination mt-4 justify-content-center"}
					activeClassName={"active"}
				/>
			</Col>
		</Row>
	</>);
}

export default ShowWines;
