import React, { useState } from 'react';

import { NavLink } from 'react-router-dom'

import { Link } from 'react-scroll';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Button
} from 'reactstrap';

import { Logo } from './assets'

const MainNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar dark expand="lg" className="fixed-top">
        <Container className="main-navigation">
          <NavbarBrand><img src={Logo} alt="logo" /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <Link to="features" smooth={true} offset={-90} duration={500}>
                  Features
                </Link>
              </NavItem>

              <NavItem>
                <Link to="howitworks" smooth={true} offset={-90} duration={500}>
                  How it works
                </Link>
              </NavItem>

              <NavItem>
                <Link to="usecases" smooth={true} offset={-90} duration={500}>
                  Use cases
                </Link>
              </NavItem>

              <NavItem>
                <NavLink to="/login"><Button color="skyblue">Sign in</Button></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default MainNavbar;